import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'
import FormsService from '@/services/forms.service'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import PreviewForm from './Preview/Preview.vue'
import FormDataTable from '@/classes/Form/FormDataTable.js'

export default {
  name: 'FormsList',
  components: {
    HeaderTopDashboard,
    OverlayManager,
    PreviewForm,
  },
  data: function () {
    return {
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      total: 0,
      headers: [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: this.$t('Nombre'), align: 'start', value: 'name' },
        { text: this.$t('Actualizado'), align: 'start', value: 'updated_at' },
        { text: this.$t('Acciones'), align: 'center', value: 'actions', sortable: false },
      ],
      ready: true,
      previewDialog: false,
      formPreviewUrl: null,
      debouncedInput: undefined,
      timeout: undefined,
      formDataTable: new FormDataTable(),
    }
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    overlayClosed () {
      this.formDataTable.previewUrl = ''
      this.formDataTable.preview = false
    },
    async preview (item) {
      const user = JSON.parse(localStorage.getItem('user'))
      this.formDataTable.previewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + item.id + '?token=' + user.token
      this.formDataTable.preview = true
      EventBus.$emit('showOverlay')
    },
    selectDates (dates) {
      this.setParamsAndGetData()
    },
    remove (id) {
      FormsService.delete({ id: id })
      .then(
        () => {
          this.getDataFromApi()
        },
        () => { },
      )
      .finally(() => {
        this.loading = false
      })
    },
    goToEdit (id) {
      this.$router.push({ name: 'formsEditor', query: { id: id } })
    },
    setTableData (data) {
      this.content = data
    },
    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = this.getParams()

      this.content = []
      this.loading = true
      FormsService.getForms(params)
        .then(
          (response) => {
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.total = response.total
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    goToEditor () {
      this.$router.push('/tools/forms/editor')
    },
  },
}
