import EventBus from '@/util/EventBus.js'

export default {
  props: {
    eventToListen: { default: 'showOverlay' },
    eventToListenClose: { default: 'closeOverlay' },
    backgroundColor: { default: '#FFFFFF' },
    heading: { default: '', required: false },
    hideDialogOverflow: { default: false, required: false },
  },
  name: 'OverlayManager',
  data: function () {
    return {
      overlayShow: false,
    }
  },
  created: function () {
    EventBus.$on(this.eventToListenClose, this.closeOverlay)
    EventBus.$on(this.eventToListen, this.showOverlay)
  },
  methods: {
    showOverlay () {
      this.overlayShow = true
      this.$emit('overlayOpened')
    },
    closeOverlay () {
      this.overlayShow = false
      this.$emit('overlayClosed')
    },
  },
}
