export default class FormDataTable {
    show
    preview
    previewUrl
    items

    constructor () {
        this.show = false
        this.preview = false
        this.previewUrl = ''
        this.items = []
    }
}
