import { Service } from './service'

const PATH = 'forms'

class FormsService extends Service {
  getFormsData (id) {
    return this.get(PATH + '/get-form/' + id)
  }

  getPreviewFromJson (params = {}) {
    return this.post(PATH + '/preview-from-json', params)
  }

  saveOrUpdate (params = {}) {
    return this.post(PATH + '/create-or-update', params)
  }

  delete (params = {}) {
    return this.post(PATH + '/remove', params)
  }

  getForms (params = {}) {
    return this.get(PATH + '/all', params)
  }

  getFormsList (params = {}) {
    return this.get(PATH + '/list', params)
  }

  getAnsweredForm (id, sendingId) {
    return this.get(PATH + '/form-answered/' + id + '/' + sendingId)
  }

  getFormAnsweredPagination (page, formId, sendingId) {
    return this.get(PATH + '/form-answered-pagination/' + page + '/' + formId + '/' + sendingId)
  }

  getCampainsFromFormId (formId) {
    return this.get(PATH + '/campains-from-form/' + formId)
  }
}

export default new FormsService()
